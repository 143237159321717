import { Container, Typography, Box } from '@mui/material';
import useWordings from 'locales/wordings/useWordings';
import Lottie from 'lottie-react';
import invalidChainAnimation from '../assets/lotties/invalidChain.json';

const InvalidChain = () => {
  const { invalidChainWordings } = useWordings();

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box sx={{ maxWidth: 400 }}>
        <Lottie animationData={invalidChainAnimation} />
      </Box>
      <Typography variant="h6" textAlign="center" sx={{ py: 3 }}>
        {invalidChainWordings.title}
      </Typography>
      <Typography variant="subtitle1" textAlign="center">
        {invalidChainWordings.descLine1}
      </Typography>
    </Container>
  );
};

export default InvalidChain;

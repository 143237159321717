import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
// hooks
import useWordings from '../../locales/wordings/useWordings';
import useAllMysteryBoxDetails from 'hooks/useAllMysteryBoxDetails';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

interface DashboardSidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
}

export default function DashboardSidebar(props: DashboardSidebarProps) {
  const { isOpenSidebar, onCloseSidebar } = props;
  const { pathname } = useLocation();
  const { offerWordings, menuWordings } = useWordings();
  const navigate = useNavigate();
  const { basic } = useAllMysteryBoxDetails();

  const sidebarConfig = [
    {
      subheader: '',
      items: [
        {
          title: menuWordings.shop,
          path: '/shop',
          icon: <Icon icon="majesticons:shopping-cart" width={22} height={22} />
        },
        {
          title: menuWordings.myMysteryBox,
          path: '/my-mystery-box',
          icon: <Icon icon="eva:inbox-fill" width={22} height={22} />
        },
        {
          title: menuWordings.myCollection,
          path: '/my-collection',
          icon: <Icon icon="bi:images" width={22} height={22} />
        },
        {
          title: menuWordings.playGame,
          path: '/play-game',
          icon: <Icon icon="ion:rocket-sharp" width={22} height={22} />
        },
        {
          title: 'My Manager',
          path: '/my-manager',
          icon: <Icon icon="fluent:circle-half-fill-16-filled" width={22} height={22} />
        }
      ]
    }
  ];

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Logo />
        </Box>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
      {pathname !== '/shop' ? (
        <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
          <Stack
            alignItems="center"
            spacing={3}
            sx={{
              p: 2.5,
              pt: 5,
              borderRadius: 2,
              position: 'relative'
            }}
          >
            <Box
              component="img"
              src="/static/boxes/basic.svg"
              sx={{ width: 100, position: 'absolute', top: -50 }}
            />

            <Box sx={{ textAlign: 'center' }}>
              <Typography gutterBottom variant="h6">
                {offerWordings.mysteryBox}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {offerWordings.fromOnly} {basic?.priceNative} BNB
              </Typography>
            </Box>

            <Button variant="contained" onClick={() => navigate('/')}>
              {offerWordings.buyNow}
            </Button>
          </Stack>
        </Box>
      ) : (
        ''
      )}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

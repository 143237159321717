import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Grid, Typography, Divider, Card, TextField, Button, MenuItem } from '@mui/material';
import useModal from '../hooks/useModal';
import AvatarsBody from './modals/AvatarsBody';
import { ManagerContext } from 'contexts/ManagerContext';
import { useMoralisCloudFunction } from 'react-moralis';
import { CharacterNFT } from '../types/nftTypes';
import TransactionErrorBody from 'components/modals/TransactionErrorBody';
import { avatars } from 'utils/avatars';

import useWordings from '../locales/wordings/useWordings';
import TransactionInProcessBody from './modals/TransactionInProcessBody';
import { TokenTVCContext } from 'contexts/TokenTVCContext';

interface ManagerResponse {
  age: number;
  balanceMGER: number;
  especiality: string;
  name: string;
  urlImageCharacter: string;
  urlImageManager: string;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  age: Yup.number()
    .min(18, 'You are too young to be a Manager')
    .max(99, 'Have you found the inmortality potion?')
    .required('Age is required'),
  speciality: Yup.string().required('A speciality it is required'),
  star: Yup.number().required('A star is required.')
});

const currencies = [
  {
    value: 'Rock',
    label: 'Rock'
  },
  {
    value: 'Pop',
    label: 'Pop'
  },
  {
    value: 'K-Pop',
    label: 'K-Pop'
  },
  {
    value: 'Punk',
    label: 'Punk'
  },
  {
    value: 'Trap',
    label: 'Trap'
  },
  {
    value: 'Reggae',
    label: 'Reggae'
  }
];

export default function CreateManagerForm() {
  const { data, fetch, isLoading } = useMoralisCloudFunction('getMyCollection');
  const { setTVCBalance } = useContext(TokenTVCContext);
  const [error, setError] = useState(false);
  const [collection, setCollection] = useState<Array<CharacterNFT>>([]);
  const navigate = useNavigate();
  const { setManager, manager, setUpdate, getCredential } = useContext(ManagerContext);
  const { BaseModal, open, handleOpen, handleClose: closeModal } = useModal();

  const [buying, setBuying] = useState(false);
  const [name, setName] = useState(manager?.name ? manager?.name : '');
  const [age, setAge] = useState(manager?.age ? manager.age : null);
  const [avatar, setAvatar] = useState({
    src: manager?.managerAvatarId ? avatars[manager.managerAvatarId - 1].src : '',
    id: manager?.managerAvatarId ? manager.managerAvatarId : 0
  });

  const [speciality, setSpeciality] = useState(manager?.especiality ? manager.especiality : '');
  const [star, setStar] = useState(manager?.tokenId ? manager.tokenId : '');
  const [hasCredential, setHasCredential] = useState(
    manager?.hasCredential ? manager.hasCredential : false
  );

  const [editing, setEditing] = useState(false);
  const { credentialWording } = useWordings();

  const {
    fetch: createCredential,
    data: createData,
    error: credentialError,
    isLoading: createIsLoading
  } = useMoralisCloudFunction(
    'createManager',
    {
      name: name,
      age: age,
      especiality: speciality,
      managerImageId: avatar.id,
      tokenId: star
    },
    { autoFetch: false }
  );

  const {
    fetch: updateCredential,
    data: updateData,
    error: updateError,
    isLoading: updateIsLoading
  } = useMoralisCloudFunction(
    'updateManager',
    {
      name: name,
      age: age,
      especiality: speciality,
      managerImageId: avatar?.id,
      tokenId: star
    },
    { autoFetch: false }
  );

  useEffect(() => {
    if (createData) {
      const res = createData as ManagerResponse;
      setManager(res);
      setTVCBalance(res.balanceMGER);
      navigate('/my-manager');
    }
    if (updateData) {
      setManager(updateData);
      navigate('/my-manager');
    }
  }, [createData, updateData]);

  useEffect(() => {
    if (credentialError) setError(true);
  }, [credentialError]);

  const handleCreate = () => {
    setBuying(true);
    createCredential({ onSuccess: () => getCredential() });
  };

  const handleUpdate = () => {
    setEditing(true);
    updateCredential({ onSuccess: setUpdate(true) });
  };

  useEffect(() => {
    if (hasCredential && editing) handleUpdate();
  }, [manager, editing]);
  //Getting NFTS
  useEffect(() => {
    if (manager) {
      fetch();
    }
  }, []);

  useEffect(() => {
    if (data) {
      const characters = data as CharacterNFT[];
      setCollection(characters);
    }
  }, [data, isLoading]);
  ////

  const handleClose = () => {
    closeModal();
    setError(false);
    return true;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name,
      age: age,
      speciality: speciality,
      star: star
    },

    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      if (avatar.id === 0) {
        console.log('error: select avatar please.');
      } else if (hasCredential) {
        setManager({ ...values, managerImageId: avatar.id });
        setEditing(true);
        handleOpen();
      } else {
        setManager({ ...values, managerImageId: avatar.id });
        handleCreate();
        handleOpen();
      }
    }
  });

  return (
    <Card>
      <Grid
        container
        columns={{ xs: 4, md: 12 }}
        sx={{
          padding: '30px 30px 15px 30px',
          display: 'flex',
          justifyContent: 'center'
        }}
        spacing={2}
      >
        <Grid item md={3} xs={2}>
          {avatar.id !== 0 ? (
            <img
              style={{ width: '17rem', height: '14rem', cursor: 'pointer' }}
              onClick={() => handleOpen()}
              src={avatar.src}
            ></img>
          ) : (
            <Card
              sx={{
                width: '100%',
                height: '250px',
                background: '#C4C4C4',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => handleOpen()}
            >
              <div
                style={{
                  height: '50px',
                  width: '50px',
                  border: '2px solid white',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '2em'
                }}
              >
                +
              </div>
            </Card>
          )}
        </Grid>
        <Grid item md={9} xs={6}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container md={12} spacing={1}>
              <Grid item xs={6} md={6} mt={2}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label={credentialWording.back.name} //add wording here
                  value={formik.values.name}
                  onChange={(e) => setName(e.target.value)}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item md={6} xs={6} mt={2}>
                <TextField
                  fullWidth
                  id="age"
                  name="age"
                  label={credentialWording.back.age}
                  type="number"
                  value={formik.values.age}
                  onChange={(e) => setAge(Number(e.target.value))}
                  error={formik.touched.age && Boolean(formik.errors.age)}
                  helperText={formik.touched.age && formik.errors.age}
                />
              </Grid>
              <Grid item md={12} xs={6} mt={2}>
                <TextField
                  fullWidth
                  id="speciality"
                  name="speciality"
                  label={credentialWording.back.speciality}
                  select
                  value={formik.values.speciality}
                  onChange={(e) => setSpeciality(e.target.value)}
                  error={formik.touched.speciality && Boolean(formik.errors.speciality)}
                  helperText={formik.touched.speciality && formik.errors.speciality}
                >
                  {currencies.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        padding: '16px 0px 16px 10px',
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={12} mt={2}>
                <TextField
                  fullWidth
                  id="star"
                  name="star"
                  label="Star"
                  select
                  value={formik.values.star}
                  onChange={(e) => setStar(e.target.value)}
                  error={formik.touched.star && Boolean(formik.errors.star)}
                  helperText={formik.touched.star && formik.errors.star}
                >
                  {collection.map((star) => {
                    return (
                      <MenuItem
                        key={star.tokenId}
                        value={star.tokenId}
                        sx={{
                          padding: '16px 0px 16px 10px',
                          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                          display: 'flex'
                        }}
                      >
                        <img
                          alt={star.tokenId}
                          src={star.imageUrl}
                          style={{ marginRight: '4px', height: '25px', width: '20px' }}
                        ></img>{' '}
                        Id: {star.tokenId} | Exp: {star.experience} | Talent: {star.talent} |
                        Energía: {star.totalEnergy} | Moral: {star.moral}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item md={12} mt={2}>
                <Typography
                  sx={{
                    mt: '10em',
                    textAlign: 'right',
                    fontWeight: '600',
                    fontSize: '1.2em'
                  }}
                >
                  Total: {manager.hasCredential ? '0 TVC' : '2000 TVC'}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ pt: 2 }} />
            <Grid
              item
              md={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'space-around',
                padding: '15px'
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{ marginRight: '10px' }}
                onClick={() => navigate('/my-manager')}
              >
                Cancel
              </Button>
              <Button color="primary" variant="contained" type="submit">
                {manager.hasCredential
                  ? credentialWording.credential.update
                  : credentialWording.credential.obtain}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <BaseModal open={open} handleClose={() => handleClose()}>
        {!error && !buying && !editing ? (
          <AvatarsBody
            avatar={avatar}
            setAvatar={setAvatar}
            handleClose={() => handleClose()}
          ></AvatarsBody>
        ) : error && buying ? (
          <TransactionErrorBody handleClose={() => setError(false)} />
        ) : (
          <TransactionInProcessBody />
        )}
      </BaseModal>
    </Card>
  );
}

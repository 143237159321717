import { useContext } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/mui/useAuth';
// components
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import ServerErrorBody from './components/modals/ServerErrorBody';
import BaseModal from './components/modals/BaseModal';
import Music from 'components/Music';
// context
import { ModalServerErrorContext } from 'contexts/ModalServerErrorContext';
import InstallMetamaskModal from 'components/modals/MetamaskInstallModal';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  const { handleClose, open } = useContext(ModalServerErrorContext);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <NotistackProvider>
            <GlobalStyles />
            <ProgressBarStyle />
            <BaseOptionChartStyle />
            <ScrollToTop />
            <GoogleAnalytics />
            <Music />
            <InstallMetamaskModal />
            <BaseModal open={open} handleClose={async () => handleClose()}>
              <ServerErrorBody />
            </BaseModal>
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

import { useState } from 'react';
// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Divider,
  Card,
  TextField,
  Button,
  MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Page from '../components/Page';
import InvalidChain from './InvalidChain';

// hooks
import useCheckChain from 'hooks/useCheckChain';
import useWordings from 'locales/wordings/useWordings';
import CreateManagerForm from '../components/CreateManagerForm';

// ----------------------------------------------------------------------

export default function CreateManager() {
  const { credentialWording } = useWordings();
  const { invalidChain } = useCheckChain();

  //Form intialization

  return (
    <Page title="My Manager">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4" gutterBottom>
            Detalles de tu compra
          </Typography>
          <Divider sx={{ pt: 2 }} />
        </Box>
        {invalidChain ? <InvalidChain /> : <CreateManagerForm></CreateManagerForm>}
      </Container>
    </Page>
  );
}

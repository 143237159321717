import { useState, useEffect } from 'react';
import { useChain, useMoralis } from 'react-moralis';
import { DataBox } from 'types/nftTypes';
import useMysteryBoxDetails from './useMysteryBoxDetails';

export default function useAllMysteryBoxDetails() {
  const { chainId } = useChain();
  const { isAuthenticated } = useMoralis();

  const {
    box: boxBasic,
    error: errorBasic,
    fetch: fetchBasic,
    isFetching: isFetchingBasic,
    isLoading: isLoadingBasic
  } = useMysteryBoxDetails(1);

  const {
    box: boxCommon,
    error: errorCommon,
    fetch: fetchCommon,
    isFetching: isFetchingCommon,
    isLoading: isLoadingCommon
  } = useMysteryBoxDetails(2);

  const {
    box: boxPremium,
    error: errorPremium,
    fetch: fetchPremium,
    isFetching: isFetchingPremium,
    isLoading: isLoadingPremium
  } = useMysteryBoxDetails(3);

  const [basic, setBasic] = useState<DataBox | undefined>();
  const [common, setCommon] = useState<DataBox | undefined>();
  const [premium, setPremium] = useState<DataBox | undefined>();

  const getAllBoxes = () => {
    fetchBasic();
    fetchCommon();
    fetchPremium();
  };

  useEffect(() => {
    const deployChainId = process.env.REACT_APP_CHAIN_ID;
    if (isAuthenticated && deployChainId && chainId === deployChainId) {
      getAllBoxes();
    } else {
      setBasic(undefined);
      setCommon(undefined);
      setPremium(undefined);
    }
  }, [chainId, isAuthenticated]);

  useEffect(() => {
    if (boxBasic) setBasic(boxBasic);
    if (boxCommon) setCommon(boxCommon);
    if (boxPremium) setPremium(boxPremium);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxBasic, boxCommon, boxPremium]);

  return {
    getAllBoxes,
    basic,
    common,
    premium,
    error: errorBasic || errorCommon || errorPremium,
    isFetching: isFetchingBasic || isFetchingCommon || isFetchingPremium,
    isLoading: isLoadingBasic || isLoadingCommon || isLoadingPremium
  };
}

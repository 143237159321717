import { useRef, useState, useEffect, useContext } from 'react';
import { shortAddress } from 'utils/addressShort';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, IconButton, Avatar, Stack, Typography, Hidden } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import TokenDetail from './TokenDetail';
// hooks
import useLogin from '../../hooks/useLogin';
import useUser from '../../hooks/useUser';
import useWordings from 'locales/wordings/useWordings';
// context
import { TokenTVCContext } from 'contexts/TokenTVCContext';
// config
import contractConfig from '../../contracts/config';
// ----------------------------------------------------------------------
const TVC = contractConfig.characters.address; // aca tenemos que tomar la del ERC20

export default function AccountBar() {
  const { TVCBalance } = useContext(TokenTVCContext);
  const { sessionWordings } = useWordings();
  const { user, endSession, initSession } = useLogin();
  const { balance, getBalance } = useUser();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      initSession();
    } else {
      getBalance(user?.attributes.ethAddress);
    }
  }, [user]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          maxWidth: 500
        }}
      >
        {user && (
          <Hidden smDown>
            <TokenDetail image="/static/tokens/tvc.svg" value={TVCBalance.toString()} />
            <TokenDetail image="/static/tokens/bnb.svg" value={balance} />
          </Hidden>
        )}
      </Stack>
      <Stack
        justifyContent="center"
        sx={{
          p: 0,
          borderRadius: 2,
          position: 'relative',
          bgcolor: '#1890FF'
        }}
      >
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: '0px 5px 0px 0px',
            width: '100%',
            height: 35,
            bgcolor: 'grey',

            ...(open && {
              '&:before': {
                zIndex: -100,
                content: "''",

                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[600], 0.42)
              }
            })
          }}
        >
          <Avatar src="/static/avatars/girl.svg" alt="photoURL" />
          <Typography color={'white'}>{shortAddress(user?.attributes.ethAddress)}</Typography>
        </IconButton>
      </Stack>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ my: 1.5, px: 1 }}>
          <Hidden smUp>
            <Box sx={{ mb: 2 }}>
              <TokenDetail image="/static/tokens/tvc.svg" value={TVCBalance.toString()} />
            </Box>
            <Box>
              <TokenDetail image="/static/tokens/bnb.svg" value={balance} />
            </Box>
          </Hidden>
        </Box>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => endSession()}>
            {sessionWordings.disconnect}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

import { ReactNode, createContext, useState } from 'react';
// ----------------------------------------------------------------------

export type ModalServerErrorProps = {
  open: boolean;
  handleOpen: VoidFunction;
  handleClose: VoidFunction;
};

const initialState: ModalServerErrorProps = {
  open: false,
  handleOpen: () => {},
  handleClose: () => {}
};

const ModalServerErrorContext = createContext(initialState);

type ModalServerErrorProviderProps = {
  children: ReactNode;
};

function ModalServerErrorProvider({ children }: ModalServerErrorProviderProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalServerErrorContext.Provider
      value={{
        open,
        handleClose,
        handleOpen
      }}
    >
      {children}
    </ModalServerErrorContext.Provider>
  );
}

export { ModalServerErrorProvider, ModalServerErrorContext };

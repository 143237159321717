import useTranslations from '../../hooks/useTranslations';

const useWordings = () => {
  const { translate } = useTranslations();

  const menuWordings = {
    shop: translate('menu.shop'),
    infoShop: translate('menu.infoShop'),
    myMysteryBox: translate('menu.myMysteryBox'),
    myCollection: translate('menu.myCollection'),
    playGame: translate('menu.playGame')
  };

  const offerWordings = {
    mysteryBox: translate('offer.mysteryBox'),
    fromOnly: translate('offer.fromOnly'),
    buyNow: translate('offer.buyNow')
  };

  const boxesWordings = {
    basic: translate('boxes.basic'),
    common: translate('boxes.common'),
    premium: translate('boxes.premium'),
    lowCharacteristics: translate('boxes.lowCharacteristics'),
    highCharacteristics: translate('boxes.highCharacteristics'),
    extremeCharacteristics: translate('boxes.extremeCharacteristics'),
    available: translate('boxes.available'),
    new: translate('boxes.new'),
    buy: translate('boxes.buy'),
    open: translate('boxes.open')
  };

  const nftWordings = {
    singer: translate('nft.singer'),
    energy: translate('nft.energy'),
    talent: translate('nft.talent'),
    moral: translate('nft.moral'),
    silver: translate('nft.silver'),
    silverBrown: translate('nft.silverBrown'),
    gold: translate('nft.gold'),
    goldRose: translate('nft.goldRose'),
    platinum: translate('nft.platinum'),
    platinumFire: translate('nft.platinumFire'),
    diamond: translate('nft.diamond'),
    apprentice: translate('nft.apprentice'),
    amateur: translate('nft.amateur'),
    professional: translate('nft.professional'),
    legend: translate('nft.legend')
  };

  const managerWordings = {
    manager: translate('manager.manager'),
    title: translate('manager.title'),
    desc1: translate('manager.desc1'),
    desc2: translate('manager.desc2'),
    buttonText: translate('manager.buttonText')
  };

  const credentialWording = {
    back: {
      name: translate('credential.back.name'),
      age: translate('credential.back.age'),
      speciality: translate('credential.back.speciality')
    },
    credential: {
      obtain: translate('credential.credential.obtain'),
      update: translate('credential.credential.update')
    }
  };

  const discountOrderModalWordings = {
    title: translate('discountOrderModal.title'),
    descLine1: translate('discountOrderModal.descLine1'),
    discountCodes: translate('discountOrderModal.discountCodes'),
    apply: translate('discountOrderModal.apply'),
    subTotal: translate('discountOrderModal.subTotal'),
    total: translate('discountOrderModal.total'),
    discount: translate('discountOrderModal.discount'),
    buttonText: translate('discountOrderModal.buttonText')
  };

  const purchaseCongratsModalWordings = {
    title: translate('purchaseCongratsModal.title'),
    descLine1: translate('purchaseCongratsModal.descLine1'),
    descLine2: translate('purchaseCongratsModal.descLine2'),
    buttonText: translate('purchaseCongratsModal.buttonText')
  };

  const purchaseErrorModalWordings = {
    title: translate('purchaseErrorModal.title'),
    descLine1: translate('purchaseErrorModal.descLine1'),
    buttonText: translate('purchaseErrorModal.buttonText')
  };

  const serverErrorModalWordings = {
    title: translate('serverErrorModal.title'),
    descLine1: translate('serverErrorModal.descLine1'),
    descLine2: translate('serverErrorModal.descLine2'),
    descLine3: translate('serverErrorModal.descLine3')
  };

  const limitOvertimeModalWordings = {
    title: translate('limitOvertime.title'),
    announce: translate('limitOvertime.announce'),
    descLine1: translate('limitOvertime.descLine1'),
    button: translate('limitOvertime.button')
  };

  const limitOvertimeByUserModalWordings = {
    title: translate('limitOvertimeByUser.title'),
    titleGetCred: translate('limitOvertimeByUser.titleGetCred'),
    announce: translate('limitOvertimeByUser.announce'),
    descLine1: translate('limitOvertimeByUser.descLine1'),
    button: translate('limitOvertimeByUser.button')
  };

  const metamaskModalWordings = {
    title: translate('metamaskModal.title'),
    descLine1: translate('metamaskModal.descLine1'),
    descLine2: translate('metamaskModal.descLine2'),
    buttonText: translate('metamaskModal.buttonText')
  };

  const metamaskMyCollectionModalWordings = {
    title: translate('metamaskMyCollectionModal.title'),
    descLine1: translate('metamaskMyCollectionModal.descLine1'),
    descLine2: translate('metamaskMyCollectionModal.descLine2'),
    buttonText: translate('metamaskMyCollectionModal.buttonText')
  };

  const metamaskInstallModalWordings = {
    title: translate('metamaskInstallModal.title'),
    descLine1: translate('metamaskInstallModal.descLine1'),
    descLine2: translate('metamaskInstallModal.descLine2'),
    buttonText: translate('metamaskInstallModal.buttonText')
  };

  const openNFTModalWordings = {
    title: translate('openNFTModal.title'),
    descLine1: translate('openNFTModal.descLine1'),
    buttonText: translate('openNFTModal.buttonText')
  };

  const emptyMysteryBoxWordings = {
    title: translate('emptyMysteryBox.title'),
    descLine1: translate('emptyMysteryBox.descLine1'),
    descLine2: translate('emptyMysteryBox.descLine2')
  };

  const emptyNFTWordings = {
    title: translate('emptyNFT.title'),
    descLine1: translate('emptyNFT.descLine1'),
    descLine2: translate('emptyNFT.descLine2')
  };

  const maintenanceWordings = {
    title: translate('maintenance.title'),
    descLine1: translate('maintenance.descLine1'),
    descLine2: translate('maintenance.descLine2')
  };

  const sessionWordings = {
    login: translate('session.login'),
    disconnect: translate('session.disconnect'),
    connect: translate('session.connect'),
    signMessage: translate('session.signMessage')
  };

  const bannerWordings = {
    title: translate('banner.title'),
    content: translate('banner.content')
  };

  const invalidChainWordings = {
    title: translate('invalidChain.title'),
    descLine1: translate('invalidChain.descLine1')
  };

  const processingTranslationWording = {
    title: translate('processingTranslationModal.content')
  };

  const availableStatusWordings = {
    available: translate('availableStatus.available'),
    new: translate('availableStatus.new'),
    soldOut: translate('availableStatus.soldOut')
  };

  const page404Wordings = {
    title: translate('page404.title'),
    descLine1: translate('page404.descLine1'),
    descLine2: translate('page404.descLine2'),
    buttonText: translate('page404.buttonText')
  };

  const labelWordings = {
    limitedEdition: translate('labels.limitedEdition'),
    new: translate('labels.new'),
    comingSoon: translate('labels.comingSoon')
  };

  const boxPendingAlert = {
    title: translate('boxPendingAlert.title'),
    content: translate('boxPendingAlert.content')
  };

  const timeWordings = {
    hours: translate('time.hours'),
    hour: translate('time.hour'),
    minutes: translate('time.minutes'),
    minute: translate('time.minute'),
    seconds: translate('time.seconds'),
    second: translate('time.second')
  };

  const musicWordings = {
    title: translate('music.title')
  };

  const whiteListAnnounceWordings = {
    title: translate('announces.whiteList.title'),
    description1: translate('announces.whiteList.description1'),
    description2: translate('announces.whiteList.description2'),
    date: {
      title: translate('announces.whiteList.date.title'),
      value: translate('announces.whiteList.date.value')
    },
    listing: {
      title: translate('announces.whiteList.listing.title'),
      value: translate('announces.whiteList.listing.value')
    },
    preSale: {
      title: translate('announces.whiteList.preSale.title'),
      value: translate('announces.whiteList.preSale.value')
    },
    btn: {
      cancelParticipation: translate('announces.whiteList.btn.cancelParticipation'),
      acceptParticipation: translate('announces.whiteList.btn.acceptParticipation'),
      acceptResponse: translate('announces.whiteList.btn.acceptResponse'),
      cancelResponse: translate('announces.whiteList.btn.cancelResponse')
    },
    selected: {
      rejected: translate('announces.whiteList.selected.rejected'),
      accepted: translate('announces.whiteList.selected.accepted')
    },
    modal: {
      base: translate('announces.whiteList.modal.base'),
      title: {
        acceptTitle: translate('announces.whiteList.modal.title.acceptTitle'),
        declineTitle: translate('announces.whiteList.modal.title.declineTitle')
      },
      description: {
        acceptDescription: translate('announces.whiteList.modal.description.acceptDescription'),
        declineDescription: translate('announces.whiteList.modal.description.declineDescription')
      }
    }
  };

  const wordings = {
    menuWordings,
    offerWordings,
    boxesWordings,
    nftWordings,
    managerWordings,
    credentialWording,
    discountOrderModalWordings,
    purchaseCongratsModalWordings,
    purchaseErrorModalWordings,
    serverErrorModalWordings,
    limitOvertimeModalWordings,
    limitOvertimeByUserModalWordings,
    sessionWordings,
    metamaskModalWordings,
    metamaskMyCollectionModalWordings,
    metamaskInstallModalWordings,
    openNFTModalWordings,
    emptyMysteryBoxWordings,
    emptyNFTWordings,
    invalidChainWordings,
    processingTranslationWording,
    maintenanceWordings,
    bannerWordings,
    availableStatusWordings,
    page404Wordings,
    labelWordings,
    boxPendingAlert,
    timeWordings,
    musicWordings,
    whiteListAnnounceWordings
  };

  return wordings;
};

export default useWordings;

import useLogin from 'hooks/useLogin';
import { ReactNode, createContext, useState, useEffect } from 'react';
import { useMoralisCloudFunction } from 'react-moralis';

// ----------------------------------------------------------------------

export type TokenTVCProps = {
  TVCBalance: number;
  setTVCBalance: Function;
  getTVCBalance: Function;
};

const initialState: TokenTVCProps = {
  TVCBalance: 0,
  setTVCBalance: Function,
  getTVCBalance: Function
};

const TokenTVCContext = createContext(initialState);

type TokenTVCProviderProps = {
  children: ReactNode;
};

function TokenTVCProvider({ children }: TokenTVCProviderProps) {
  const { user, isAuthenticated } = useLogin();
  const { data, error, fetch } = useMoralisCloudFunction('getManagerBalance', undefined, {
    autoFetch: false
  });
  const [TVCBalance, setTVCBalance] = useState(0);

  const getTVCBalance = async () => {
    if (user && isAuthenticated) {
      await fetch();
    }
  };

  useEffect(() => {
    TVCBalance === 0 && getTVCBalance();
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (data) {
      const res = data as { balance: number };
      setTVCBalance(res.balance);
    }
  }, [data]);

  useEffect(() => {
    if (error) console.log('errorTokenTVCContext', error);
  }, [error]);

  return (
    <TokenTVCContext.Provider
      value={{
        TVCBalance,
        setTVCBalance,
        getTVCBalance
      }}
    >
      {children}
    </TokenTVCContext.Provider>
  );
}

export { TokenTVCProvider, TokenTVCContext };

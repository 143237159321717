import { Avatar, Typography, Grid } from '@mui/material';

interface TokenDetailProps {
  image: string;
  value?: string;
}

const TokenDetail = ({ image, value = '0.000000' }: TokenDetailProps) => {
  const cutValue = (withDecimals = '0.000000') => {
    const [number, decimals] = withDecimals.split('.');
    return `${number}.${decimals?.substring(0, 2) || '00'}`;
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
      sx={{
        mr: 2,
        border: '1.5px solid #1890FF',
        borderRadius: '15px',
        padding: '2px 5px 2px 5px'
      }}
    >
      <Avatar
        alt="Token Detail"
        src={image}
        sx={{
          width: 28,
          height: 28,
          mr: 1
        }}
      />
      <Typography variant="body2">{cutValue(value)}</Typography>
    </Grid>
  );
};

export default TokenDetail;

import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Box, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
import AccountBar from './AccountBar';
import SettingModeSwitch from '../../components/settings/SettingModeSwitch';
import MultiLanguageSelector from '../../components/settings/MultiLanguageSelector';
// hooks
import useLogin from '../../hooks/useLogin';
import useWordings from 'locales/wordings/useWordings';
import { useChain, useMoralis } from 'react-moralis';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 82;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

interface DashboardNavBarProps {
  onOpenSidebar: VoidFunction;
}

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavBarProps) {
  const { initSession, isAuthenticated, user, isWeb3Enabled } = useLogin();
  const { account } = useMoralis();
  const { account: accountChain } = useChain();
  const { sessionWordings } = useWordings();

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />
        {/*<button onClick={async () => console.log(getUserBalances())}>see balances</button>*/}
        {!user ? (
          <Button variant="contained" onClick={() => initSession()} sx={{ px: 3 }}>
            {account || accountChain || isWeb3Enabled
              ? sessionWordings.login
              : sessionWordings.connect}
          </Button>
        ) : (
          <AccountBar />
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MultiLanguageSelector />
          {/*<SettingModeSwitch />*/}
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}

import { any } from 'prop-types';
import { ReactNode, createContext, useState, useEffect } from 'react';
import { useChain, useMoralis, useMoralisCloudFunction } from 'react-moralis';
import useLogin from 'hooks/useLogin';

// ----------------------------------------------------------------------

interface Manager {
  name: string;
  age: number | unknown;
  especiality: string;
  tokenId: string;
  hasCredential: boolean;
  urlImageCharacter?: string;
  urlImageManager?: string;
  managerAvatarId?: number;
}

export type ManagerProps = {
  manager: Manager;
  setManager: Function;
  update: Boolean;
  setUpdate: Function;
  getCredential: Function;
};

const initialState: ManagerProps = {
  setManager: () => {},
  getCredential: () => {},
  update: false,
  setUpdate: () => {},
  manager: {
    name: '',
    age: null,
    especiality: '',
    tokenId: '',
    hasCredential: false,
    urlImageCharacter: '',
    urlImageManager: '',
    managerAvatarId: 0
  }
};

const ManagerContext = createContext(initialState);

type ManagerProviderProps = {
  children: ReactNode;
};

function ManagerProvider({ children }: ManagerProviderProps) {
  const { user, endSession, initSession } = useLogin();
  const [update, setUpdate] = useState(initialState.update);
  const [manager, setManager] = useState(initialState.manager);

  const {
    fetch: getCredential,
    data: getData,
    error: getCredentialError,
    isLoading: getCredentialIsLoading
  } = useMoralisCloudFunction('getManager', {
    userAddress: user?.attributes.ethAddress
  });

  useEffect(() => {
    if (getData) {
      const res = getData as Manager;
      setManager(res);
    }
  }, [getData]);

  useEffect(() => {
    if (update) getCredential();
    setUpdate(false);
  }, [update]);

  return (
    <ManagerContext.Provider
      value={{
        setManager,
        manager,
        update,
        setUpdate,
        getCredential
      }}
    >
      {children}
    </ManagerContext.Provider>
  );
}

export { ManagerProvider, ManagerContext };

import { useState } from 'react';
import { formatEther } from '@ethersproject/units';
import { Moralis } from 'moralis';
import Web3 from 'web3';

const providerURL = process.env.REACT_APP_PROVIDER_URL || 'http://localhost:7545';
const web3 = new Web3(Web3.givenProvider || new Web3.providers.HttpProvider(providerURL));
export default function useUser() {
  const [balance, setBalance] = useState();

  const getBalance = async (address: string) => {
    await web3.eth.getBalance(address, function (err: Error, result: any) {
      if (err) {
        console.log(err);
      } else {
        setBalance(result && formatEther(result));
      }
    });
  };

  const listenToUpdate = async (address: string) => {
    let query = new Moralis.Query('EthTransactions');
    let subscription = await query.subscribe();

    subscription.on('create', (object) => {
      getBalance(address);
    });
  };
  return {
    balance,
    listenToUpdate,
    getBalance
  };
}

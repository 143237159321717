import { useMoralis, useChain } from 'react-moralis';
import { useEffect } from 'react';
import useUser from 'hooks/useUser';
import Moralis from 'moralis';
import useWordings from 'locales/wordings/useWordings';

export default function useLogin() {
  const {
    authenticate,
    logout,
    account,
    user,
    web3,
    isAuthenticated,
    isWeb3Enabled,
    isWeb3EnableLoading,
    web3EnableError
  } = useMoralis();
  const { switchNetwork, chainId } = useChain();
  const { listenToUpdate } = useUser();
  const { sessionWordings } = useWordings();
  const deployChainId = process.env.REACT_APP_CHAIN_ID!;

  const checkChain = async () => {
    if (chainId !== deployChainId) {
      await switchNetwork(deployChainId);
    }
  };

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      checkChain();
    }
  }, [chainId, isAuthenticated, isWeb3Enabled]);

  const init = async (user: Moralis.User<Moralis.Attributes>) => {
    await Moralis.Web3.enableWeb3();
    await listenToUpdate(user.attributes.ethAddress);
  };

  useEffect(() => {
    if (user && isAuthenticated) {
      init(user);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    Moralis.Web3.onAccountsChanged(() => {
      logout();
    });
  }, [user]);

  const provider = web3?.givenProvider;
  const chainIdDecimal = process.env.REACT_APP_CHAIN_ID_DECIMAL! as unknown as number;
  const initSession = async () => {
    if (!isAuthenticated) {
      await authenticate({
        provider: provider,
        chainId: chainIdDecimal, //no funciona, gracias moralis
        signingMessage: sessionWordings.signMessage,
        onError: (error) => {
          return;
        },
        onSuccess: () => Moralis.Web3.enableWeb3()
      });
    }
  };

  const endSession = () => {
    if (user) {
      const userName = user.get('username');
      if (userName) {
        logout();
      }
    }
  };

  return {
    user,
    provider,
    account,
    initSession,
    endSession,
    logout,
    isAuthenticated,
    isWeb3Enabled,
    isWeb3EnableLoading,
    web3EnableError
  };
}
